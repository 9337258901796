<template>
  <v-container>
    <v-row>

      <v-col lg="6" cols="12" class="d-flex flex-wrap align-content-center justify-center">
        <div class="text-center flex-wrap justify-center">
          <h2 class="text-center text-h3">Kontakt</h2>

          <p class="text-body-1">
            <a href="https://g.page/Wacholderhuette?share">Neuer Weg 16<br/>
              56729 Langscheid</a>
          </p>
          <div class="text-body-1"><v-icon>mdi-email</v-icon>
              <MailLink/>
          </div>
          <div class="text-body-1"><v-icon size="large">mdi-phone</v-icon>
              <PhoneLink/>
          </div>
        </div>
      </v-col>
      <v-col lg="6" cols="12" class="m-0 p-0">
        <v-img :eager="true"
            :src="require('../assets/wacholderhuette_sommer_drohne.jpg')"
            contain
            width="100%"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>

    </v-row>

    <v-row>
      <v-col class="m-0 p-0">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import MailLink from "@/components/layout/MailContact.vue";
import PhoneLink from "@/components/layout/PhoneLink.vue";

export default {
  name: "ContactBlock",
  components: {PhoneLink, MailLink},
  data() {
    return {
      markers: [
        {
          id: "a",
          position: { lat: 50.38342887628354, lng: 7.108138100526698 }
        }
      ],
    }
  },
  computed: {
    mapConfig () {
      return {
        center: { lat: 50.38342887628354, lng: 7.108138100526698 },
      }
    },
  },
  mounted() {
  }
}
</script>