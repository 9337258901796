<template>
  <div class="pt-5 py-2 d-block align-content-center">

    <v-img :eager="true"  :src="'https://api.bakery.wacholderhuette.de/thumbs/thumb_'+cake.img_url" v-if="cake.img_url">
      <template v-slot:placeholder>
        <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
        >
          <v-progress-circular
              indeterminate
              color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <p class="font-weight-bold d-block pb-0 mb-0" :class="{'text-decoration-line-through  text--disabled': cake.soldout}">
      {{ cake.name }}
    </p>

    <div v-if="live && !cake.soldout" class="text-caption d-block">noch <span class="text-h6" >{{ cake.count }}</span> Stücke</div>
    <div class="d-flex justify-center">
      <p class="rotated text-center" v-if="cake.count<1 || cake.soldout" :style="cssVars">{{ emptyPhrase }}</p>
    </div>

  </div>
</template>

<script>
export default {
  name: "CakeItem",
  data() {
    return {
      emptyPhrases: [
        "Leider schon weg",
        "Der war zu lecker..",
        "hmmmm, ich war zu begehrt!",
        "Oh! ausverkauft",
        "ausverkauft",
        "vergriffen",
        "leergefuttert",
        "ausverkauft", "nichts mehr da",
        "Alle alle",
        "Kein Krümel mehr da",
        "die anderen waren schneller",
        "zu spät"
      ]
    }
  },
  props: {
    cake: Object,
    live: Boolean
  },
  computed: {
    cssVars() {
      let left = Math.round(Math.random()) === 0;
      let rotate = Math.round( (left?  (Math.random() * 5 + 5)  : (360 - (Math.random() * 5+ 5))) ) ;
      //let margin = Math.round( left ? (Math.random() * 2)  : (Math.random() * 2)  +2 );

      return {
        '--rotation': rotate + "deg",
        //'--mleft': margin + "%"
      }
    },
    emptyPhrase() {
      return this.emptyPhrases[Math.floor(Math.random() * this.emptyPhrases.length)]
    }
  }
}
</script>

<style scoped>
.rotated {
  font-family: 'Alex Brush', cursive !important;
  font-size: 1.3em;
  letter-spacing: 1px;
  line-height: 20px;
  text-shadow: 1px 1px 6px #FFF;
  color: #FF1966;
  margin-top: 10px;
  font-weight: bold;
  width: 150px;
  position: absolute;
  -moz-transform: rotate(var(--rotation));
  -webkit-transform: rotate(var(--rotation));
  -o-transform: rotate(var(--rotation));
  -ms-transform: rotate(var(--rotation));
  transform: rotate(var(--rotation));
  z-index: 100;
}
</style>