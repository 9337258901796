<template>
  <v-container>
    <v-row>
      <v-col>
        <h2 class="text-center text-h3 mb-5">
          Über uns
        </h2>
      </v-col>
    </v-row>
    <SpaceDivider/>
    <v-row class="text-center py-0 my-0" no-gutters>
      <v-col lg="6" sm="12" order="first">
        <v-img :eager="true"
            :src="require('../assets/scene-cappuccino.jpg')"
            contain
            width="100%"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col class="d-flex flex-wrap align-content-center p-3" sm="12" lg="6" order="last">
        <div class="text-body-1">
          Auf 550m Höhe, am beliebten <a
            href="https://tourenportal.traumpfade.info/de/tour/wanderung/traumpfad-wacholderweg/1386188/">Traumpfad
          Wacholderweg</a> und am <a
            href="https://tourenportal.traumpfade.info/de/tour/wanderung/traumpfaedchen-langscheider-wacholderblick/22862381/">Traumpfädchen
          Langscheider Wacholderblick</a> liegt unser schönes Wanderlokal und bietet seit dem 15. Januar unter neuer
          Bewirtung regionale und saisonale Spezialitäten an.
        </div>
      </v-col>
    </v-row>

    <v-row class="text-center py-0 my-0" no-gutters>
      <v-col class="d-flex flex-wrap align-content-center p-3" lg="6" sm="12" order="last" order-sm="last"
             order-md="first">
        <div class="text-body-1">
          Anfänglich werden wir den Betrieb am Wochenende mit einem Außer-Haus-Verkauf wieder aufnehmen. Stetig
          wechselnde Gerichte werden für Sie zur Mitnahme frisch zubereitet.
        </div>
      </v-col>

      <v-col lg="6" sm="12" order-sm="first" order-md="last">
        <v-img :eager="true"
            :src="require('../assets/scene-waffle.jpg')"
            contain
            width="100%"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <v-row class="text-center py-0 my-0" no-gutters>
      <v-col lg="6" sm="12">
        <v-img :eager="true"
            :src="require('../assets/scene-punch.jpg')"
            contain
            width="100%"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
      <v-col class="d-flex flex-wrap align-content-center p-3" lg="6" sm="12">
        <div class="text-body-1">
          Kommen Sie uns gerne auf ein Heißgetränk oder eine hausgemachte Speise besuchen. Neben einer Tagessuppe bieten
          wir auch frisch gebackene Kuchen und Torten an.
          <br/><br/>
          Wir freuen uns auf Sie.
          <br/>
          <span class="signed">Ayla und Kira Kowalinski</span>
        </div>
        <div class="text-body-1">

        </div>
        <div class="text-body-1">

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SpaceDivider from "@/components/SpaceDivider";
export default {
  name: "IntroductionBlock",
  components: {SpaceDivider}
}
</script>

<style scoped>

</style>