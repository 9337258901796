<template>
  <div>
    <v-row>
      <SpaceDivider :divider="true"></SpaceDivider>
    </v-row>
    <v-row>
      <v-col cols="12" xl="4">
        <v-list>
          <v-list-item class="d-flex justify-center">
            <a href="https://www.instagram.com/wacholderhuette" target="_blank"><img :src="require('../../assets/svg/instagram.svg')" width="30px" height="30px" class="d-flex-inline"/>
              Instagram</a>
          </v-list-item>
          <v-list-item class="d-flex justify-center">
            <a href="https://www.facebook.com/wacholderhuette" target="_blank"><img :src="require('../../assets/svg/facebook.svg')" width="30px" height="30px" class="d-flex-inline"/>
              Facebook</a>
          </v-list-item>
        </v-list>

      </v-col>
      <v-col cols="12" xl="4">

        <v-list>
          <v-list-item class="d-flex justify-center">
            <a href="https://www.traumpfade.info/" target="_blank"><img :src="require('../../assets/svg/traumpfad.svg')"
                                                                        width="30px" height="30px"
                                                                        class="d-flex-inline"/> Traumpfade.info</a>
          </v-list-item>
          <v-list-item class="d-flex justify-center">
            <a href="https://vordereifel.de/" target="_blank"><img :src="require('../../assets/logo-vordereifel.png')"
                                                                   width="30px" height="30px" class="d-flex-inline"/>
              Vordereifel.de</a>
          </v-list-item>

        </v-list>

      </v-col>
      <v-col cols="12" xl="4">
        <v-list>
          <v-list-item class="d-flex justify-center">
            <ImpressumButton/>
          </v-list-item>
          <v-list-item class="d-flex justify-center">
            <LegalButton/>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center">
        <!-- <span>geöffnet Fr-So von 10-18Uhr</span><br/><br/> -->
        <v-icon>mdi-map-marker</v-icon> <a href="https://g.page/Wacholderhuette?share"> Neuer Weg 16, 56729 Langscheid</a><br/>
        <v-icon>mdi-email</v-icon> <MailLink /><br/>
        <v-icon size="large">mdi-phone</v-icon> <PhoneLink/><br/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SpaceDivider from "@/components/SpaceDivider.vue";
import ImpressumButton from "@/components/ImpressumButton.vue";
import LegalButton from "@/components/LegalButton.vue";
import MailLink from "@/components/layout/MailContact.vue";
import PhoneLink from "@/components/layout/PhoneLink.vue";

export default {
  name: "FooterBlock",
  components: {PhoneLink, MailLink, LegalButton, ImpressumButton, SpaceDivider}
}
</script>

<style scoped>

</style>