<template>
  <v-container>
    <v-row>
      <v-col cols="3" class="d-flex child-flex m-0 p-1" v-for="n in random" :key="n">
        <v-img :eager="true" :src="require('../assets/150/'+n+'.jpg')" aspect-ratio="1" class="grey lighten-2">
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <SpaceDivider/>
    <v-row>

      <v-col>
        <h2 class="text-center text-h3 mb-5">
          Die Torten- und Kuchentheke
        </h2>
        <div class="text-body-1 text-center">
          Jede Woche wird die Kuchentheke mit hausgemachten Torten, Kuchen und weiteren Backspezialitäten gefüllt.
          Insgesamt umfasst die Kuchenkarte über 60 verschiedene Rezepte und wird stetig erweitert. Neben den beliebten
          Klassikern wie die Schwarzwälderkirschtorte, Bisquitrolle oder Kirschstreusel werden auch ausgefallenere
          Kreationen wie z.B. eine Tiramisutorte, Eierlikörtorte oder Charlotte zubereitet.
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!enabled">
      <v-col>
        <SpaceDivider height="20px"/>

        <v-alert outlined color="purple">
          Sobald die Kuchentheke gefüllt ist, werden alle Kuchen & Torten hier an unseren Öffnungstagen aufgeführt.
        </v-alert>
      </v-col>

    </v-row>

    <LiveCakes v-if="enabled" :live="live"/>


    <v-img :eager="true" :src="require('../assets/scene-russicher-streusel.jpg')" contain
           :lazy-src="require('../assets/scene-russicher-streusel-thumb.jpg')" class="mt-5">
      <template v-slot:placeholder>
        <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
        >
          <v-progress-circular
              indeterminate
              color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>


  </v-container>
</template>

<script>
import LiveCakes from "@/components/menu/LiveCakes";
import SpaceDivider from "@/components/SpaceDivider";


export default {
  name: "CakeList",
  components: {SpaceDivider, LiveCakes},
  props: {
    enabled: Boolean,
    live: Boolean
  },
  computed: {
    pictures() {
      return [1, 2, 3, 4, 5, 6, 7, 8]
    },
    random() {
      let n = 4,
          arr = this.pictures,
          result = new Array(n),
          len = arr.length,
          taken = new Array(len);

      if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>