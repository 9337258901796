<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <a
            v-bind="attrs"
            v-on="on"
        >
          Impressum
        </a>
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Impressum
        </v-card-title>

        <v-card-text class="text-center pt-10">
          <p class="text-h5">Angaben gemäß § 5 TMG</p>
          <p>
            Kowalinski GbR<br/>
            <br/>
            Wachholderhütte<br/>
            Neuer Weg 16<br/>
            56729 Langscheid<br/>
          </p>
          <p class="font-weight-bold mb-1">Vertreten durch:</p>
          <p>
            Ayla Kowalinski<br/>
            Kira Kowalinski<br/>
          </p>
          <p class="text-h5">Kontakt</p>
          <p class="">
            Telefon: 026551577<br/>
            E-Mail: info@wacholderhuette.de<br/>
          </p>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <a
              @click="dialog = false"
          >
            Schließen
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


export default {
  name: "ImpressumButton",
  components: {},
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style lang="sass" scoped>

</style>