<template>
  <v-container>
    <div :style="'height:'+height"  />
    <v-divider v-if="divider"/>
    <div v-if="divider" :style="'height:'+height"  />
  </v-container>
</template>

<script>
export default {
  name: "SpaceDivider",
  props: {
    divider: Boolean,
    height: {
      type: String,
      default: "50px"
    }
  }
}
</script>

<style scoped>

</style>