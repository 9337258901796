<template>

    <v-container>

      <v-row>
        <v-col class="d-flex justify-center">

          <h2 class="text-center text-h3 mb-5">
            Die Speisen
          </h2>

        </v-col>
      </v-row>

      <MenuDownloadRow/>

      <v-row v-if="loading" class="px-5 py-5">
        <v-col>
          <p class="text-center blue--text lighten-2">Lade Speisen</p>
          <v-progress-linear buffer-value="0"
                             color="blue lighten-2"
                             stream
          ></v-progress-linear>
        </v-col>
      </v-row>

      <v-row v-for="menuItem in menu" :key="menuItem.title" class="d-flex justify-center">
        <v-col v-if="menuItem.title || menuItem.desc" cols="12" class="text-center pt-10">
          <v-banner single-line class="text-center mx-16" v-if="menuItem.title">{{ menuItem.title }}</v-banner>

          <p class="text-body-2 text-center pt-5 pb-3" v-if="menuItem.description">{{ menuItem.description }}</p>
        </v-col>
        <v-col v-for="foodItem in menuItem.items.filter(value => !value.hide)" :key="foodItem.title" cols="12" :md="foodItem.img ? 12 : 6"
               :lg="foodItem.img ? 12 : 4">
          <FoodItem :food-item="foodItem"></FoodItem>
        </v-col>
      </v-row>

    </v-container>

</template>

<script>
import FoodItem from "./menu/FoodItem.vue";
import MenuDownloadRow from "@/components/MenuDownloadRow.vue";

export default {
  name: "MenuBlock",
  components: {MenuDownloadRow, FoodItem},
  props: {
    enabled: Boolean
  },
  created() {
    this.loading = true;
    fetch("https://api.wacholderhuette.de/menus")
        .then(response => response.json())
        .then((data) => {
          this.menu = data
          this.loading = false;
        })
  },
  data() {
    return {
      loading: true,
      menu: []
    }
  }
}
</script>

