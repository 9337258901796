<template>
  <v-app>
    <v-main>
      <v-container class="container-sm wacholderhuette" style="max-width: 1320px">
        <SpaceDivider/>

        <HeaderBlock id="start" ref="start" :live-cakes="live" :show-menu="showMenu" :active-aboutus="activeAboutus" :active-cakes="activeCakes" :active-contact="activeContact" :active-menu="activeMenu" :active-openingtimes="activeOpeningtimes"/>

        <SpaceDivider height="80px"/>


        <WelcomeBlock :menu-shown="showMenu"/>
        <SpaceDivider height="20px"/>

        <OpeningTimes id="openingTimes" ref="openingTimes" v-intersect="onOpeningTimes"/>
        <SpaceDivider/>

        <IntroductionBlock id="aboutUs" ref="aboutUs" v-intersect="onAboutus"/>

        <SpaceDivider :divider="true"/>
        <CakeList id="cakes" ref="cakes" :live="live" :enabled="enabled" v-intersect="onCakes"/>

        <SpaceDivider :divider="true" v-if="showMenu"/>
        <MenuBlock id="menu" ref="menu" :enabled="showMenu" v-if="showMenu" v-intersect="onMenu" />

        <SpaceDivider :divider="true"/>

        <ContactBlock id="contact" ref="contact" v-intersect="onContact"/>

        <FooterBlock/>

      </v-container>

    </v-main>
  </v-app>
</template>

<script>
import HeaderBlock from './components/layout/HeaderBlock.vue';
import WelcomeBlock from "@/components/WelcomeBlock.vue";
import IntroductionBlock from "@/components/IntroductionBlock.vue";
import CakeList from "@/components/CakesList.vue";
import ContactBlock from "@/components/ContactBlock.vue";
import SpaceDivider from "@/components/SpaceDivider";
import OpeningTimes from "@/components/OpeningTimes";
import FooterBlock from "@/components/layout/FooterBlock.vue";
import MenuBlock from "@/components/MenuBlock.vue";

export default {
  name: 'App',

  components: {
    MenuBlock,
    FooterBlock,
    OpeningTimes,
    SpaceDivider,
    ContactBlock,
    CakeList,
    IntroductionBlock,
    WelcomeBlock,
    HeaderBlock,
  },

  data() {
    return {
      enabled: false,
      live: false,
      showMenu: false,
      activeAboutus: false,
      activeCakes: false,
      activeOpeningtimes: false,
      activeMenu: false,
      activeContact: false
    }
  },
  created() {
    fetch("https://api.wacholderhuette.de/probe")
        .then(response => response.json())
        .then((data) => {
          this.enabled = data.enabled;
          this.live = data.live;
          this.showMenu = data.showMenu;
        })
  },
  methods: {
    onContact(entries, observer, isIntersecting) {
      this.activeContact = isIntersecting
    },
    onMenu(entries, observer, isIntersecting) {
      this.activeMenu = isIntersecting
    },
    onCakes(entries, observer, isIntersecting) {
      this.activeCakes = isIntersecting
    },
    onAboutus(entries, observer, isIntersecting) {
      this.activeAboutus = isIntersecting
    },
    onOpeningTimes(entries, observer, isIntersecting) {
      this.activeOpeningtimes = isIntersecting
    }
  }
};
/**
 font-family: 'Space Mono', monospace;
 font-family: 'Alex Brush', cursive;

 'xs': 0,
 'sm': 340px,
 'md': 540px,
 'lg': 800px - 24px,
 'xl': 1280px - 24px
 */
</script>

<style lang="sass">




  body
    font-size: 1rem

  .v-application
    @media (min-width: 320px)
      font-size: 0.9rem
    @media (min-width: 340px)
      font-size: 1.00rem
    @media (min-width: 540px)
      font-size: 1.00rem
    @media (min-width: 800px - 24px)
      font-size: 1.1rem
    @media (min-width: 1280px - 24px)
      font-size: 1.1rem

    .wacholderhuette
      color: #333
      line-height: 1.7em
      -webkit-font-smoothing: antialiased

      .text-h2
        font-size: 2.25em!important
        font-weight: 600
        color: #000
        -webkit-font-smoothing: antialiased

      .text-h3
        font-size: 2em!important
        font-weight: 400
        color: #000

      .text-body-1
        font-size: 1.15em!important
        font-weight: 400
        line-height: 1.4em
        letter-spacing: 0.03125em !important

      .signed
        font-family: 'Alex Brush', cursive !important
        font-size: 1.75em
        display: block
        line-height: 47.6px

      a
        color: #000
        text-decoration: none


</style>


