<template>
  <v-container v-if="cakes.length>0 || cakesWithImages.length>0">
    <SpaceDivider height="20px"/>

    <v-banner class="text-center text-h6 mb-5">Heute in der Kuchentheke:</v-banner>

    <v-row v-if="!loaded" class="px-5 py-5">
      <v-col>
        <p class="text-center blue--text lighten-2">Lade Kuchentheke</p>
        <v-progress-linear buffer-value="0"
                           color="blue lighten-2"
                           stream
        ></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-if="cakesWithImages.length>0 && loaded" class="mb-10 d-flex justify-center">
      <v-col v-for="cake in cakesWithImages" :key="cake.uid" cols="12" sm="6" md="6" lg="4" xl="3" class="text-center">
        <CakeItem :cake="cake" :live="live"/>
      </v-col>
    </v-row>
    <v-row v-if="cakes.length>0 && loaded" class="mb-10 d-flex justify-center">
      <v-col v-for="cake in cakes" :key="cake.uid" cols="12" sm="6" md="6" lg="4" xl="3" class="text-center">
        <CakeItem :cake="cake" :live="live"/>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col class="text-right">
        <small class="">Letzte Aktualisierung: {{ last_updated }}</small>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
            border="top"
            colored-border
            type="info"
        >
          Wir versuchen die hier dargestellte Kuchentheke so aktuell wie möglich zu halten, dennoch kann es in
          Stoßzeiten dazu kommen, dass die hier aufgelisteten Kuchen oder Torten bereits vergriffen sind. Rufen Sie uns
          gerne
          an, bevor Sie wegen eines bestimmten Kuchens anreisen
          <v-icon>mdi-emoticon-outline</v-icon>
          <br/>
          <br/>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CakeItem from "@/components/menu/CakeItem.vue";
import SpaceDivider from "@/components/SpaceDivider";

export default {
  name: "LiveCakes",
  components: {SpaceDivider, CakeItem},
  props: {
    live: Boolean
  },
  data() {
    return {
      cakes: [],
      cakesWithImages: [],
      last_updated: null,
      loaded: false,
      loading: true,
      showInitial: true
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    loadCakes(initial) {

      this.loading = true;
      fetch("https://api.wacholderhuette.de/cakes")
          .then(response => response.json())
          .then((data) => {
            this.loaded = true
            this.cakes = data.items.filter(value => value.img_url === null || value.img_url === '');
            this.cakesWithImages = data.items.filter(value => value.img_url !== null && value.img_url !== '')
            this.last_updated = data.last_updated;
            this.loading = false;
            if (initial === true) {
              this.showInitial = false
            }
          })
    },
  },
  created() {
    this.loadCakes(true);
    setInterval(this.loadCakes, 10000);
  }
}
</script>

<style scoped>

</style>