<template>
  <v-row class="text-center">
    <v-col>
      <v-btn
          class="ma-2"
          outlined
          href="/downloads/Speisekarte-klein-mit-allergene.pdf"
          download>
        Speisekarte herunterladen *
      </v-btn>
      <br/>
      <small>* Wir müssen derzeit leider oft unsere Preise anpassen, um auf die starken Schwankungen beim Einkauf zu
        reagieren. Die hier aufgeführten Preise können von den tatsächlichen Preisen im Ladenlokal abweichen.</small>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'MenuDownloadRow'
}
</script>
