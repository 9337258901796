<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :eager="true"
            :src="require('../../assets/logo_black.svg')"
            contain
            height="120"
        >
          <template v-slot:placeholder>
            <v-row
                style="height: 120px"
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <h1 class="text-h2">Wacholderhütte</h1>
      </v-col>
    </v-row>
    <v-row v-intersect="onIntersect"></v-row>

    <div class="text-center">
      <span>
         <a href="#openingTimes" @click="$vuetify.goTo('#openingTimes', options)">Öffnungszeiten</a> <a href="#aboutUs" @click="$vuetify.goTo('#aboutUs', options)">Über uns</a> <a href="#cakes" @click="$vuetify.goTo('#cakes', options)">Kuchentheke<v-badge title="Live-Kuchentheke ist aktiv" v-if="liveCakes" color="green" :offset-y="'-10px'" :dot="true"></v-badge></a> <a href="#menu" v-if="showMenu" @click="$vuetify.goTo('#menu', options)">Speisen</a> <a href="#contact" @click="$vuetify.goTo('#contact', options)">Kontakt</a>
      </span>
    </div>
    <div :class="{'sticky menu elevation-2 d-block': stickyMenu}" class="text-center d-none">
      <span>
        <a href="#start" >Start</a> <a href="#openingTimes" @click="$vuetify.goTo('#openingTimes', options)" :class="{active: activeOpeningtimes}">Öffnungszeiten</a> <a href="#aboutUs" @click="$vuetify.goTo('#aboutUs', options)" :class="{active: activeAboutus}">Über uns</a> <a href="#cakes" @click="$vuetify.goTo('#cakes', options)" :class="{active: activeCakes}">Kuchentheke<v-badge title="Live-Kuchentheke ist aktiv" content="Live" v-if="liveCakes" color="green" :offset-y="'-10px'" :dot="true"></v-badge></a> <a href="#menu" v-if="showMenu" @click="$vuetify.goTo('#menu', options)" :class="{active: activeMenu}">Speisen</a> <a href="#contact" @click="$vuetify.goTo('#contact', options)" :class="{active: activeContact}">Kontakt</a>
      </span>
    </div>


  </v-container>
</template>

<script>
export default {
  name: 'HeaderBlock',

  methods: {
    onIntersect(entries, observer, isIntersecting) {
      this.stickyMenu = !isIntersecting;
    }
  },
  data() {
    return {
      stickyMenu: false,
      options: {
        offset: 80
      }
    }
  },
  props: {
    showMenu: Boolean,
    activeOpeningtimes: Boolean,
    activeAboutus: Boolean,
    activeCakes: Boolean,
    activeMenu: Boolean,
    activeContact: Boolean,
    liveCakes: Boolean
  }
}
</script>

<style lang="sass">
a
  margin-right: 15px
  line-height: 35px
  position: relative
  &:last-child
    margin-right: 0

.sticky
  position: fixed
  padding: 5px
  top: 0
  width: 100%
  background-color: #FFFFFF
  z-index: 1000
  left: 0

.active
  font-weight: bold

.menu-egg
  transform: rotate(5deg)
</style>
