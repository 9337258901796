<template>
    <div class="text-center">
        <div v-if="foodItem.img" class="d-flex justify-center">
            <v-img :eager="true" :alt="foodItem.title"  style="max-height: 300px" contain :src="require('../../assets/food/'+foodItem.img)">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
        </div>
        <p class="font-weight-bold text-center">
            {{foodItem.title}}
        </p>
        <div class="text-center text-caption" v-if="foodItem.variations.length === 1">{{foodItem.variations[0]}}</div>
        <div class="d-flex justify-center">
            <v-list v-if="foodItem.variations.length>1">
                <v-list-item :key="variation" v-for="variation in foodItem.variations" class="text-caption food-list-item">
                    <v-icon>mdi-chevron-right</v-icon> {{variation}}
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FoodItem",
        props: {
            foodItem: Object
        },
        methods: {

        }
    }
</script>

<style scoped lang="sass">
    .food-list-item
        min-height: 10px
        height: 20px
</style>